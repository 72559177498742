import * as formHelper from '@phoenix/helpers/form-helper';
import modalMixin from '@phoenix/mixins/modalMixin';
import FormGroup from '@maison/components/form/FormGroup.vue';
import TextField from '@maison/components/form/fields/TextField.vue';
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    FormGroup,
    PhoenixForm: defineAsyncComponent(() => import('@maison/components/form/PhoenixForm.vue')),
    TextField,
  },

  mixins: [modalMixin],

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fields: {
        orderId: {
          id: 'orderId',
          name: 'orderId',
          label: this.$t('Form.label.orderId'),
          placeholder: this.$t('Form.label.orderIdPlaceholder'),
          value: '',
          required: true,
        },
        email: {
          id: 'email',
          name: 'email',
          label: this.$t('Form.label.email'),
          value: '',
          required: true,
          type: 'email',
        },
      },
    };
  },

  computed: {
    action() {
      return window.route('api.form.sendGuestOrderToken');
    },
  },

  methods: {
    onSendSuccess() {
      this.close();
    },

    resetModal() {
      // Clear form data on closing modal with btn not submit
      formHelper.resetForm(this.fields);
    },
  },
};
