<script>
import GuestOrderTokenRequestModal from '@phoenix/mixins/components/form/GuestOrderTokenRequestModal';

export default {
  mixins: [GuestOrderTokenRequestModal],

  computed: {
    modalOptions() {
      return {
        onHidden: () => this.resetModal(),
      };
    },
  },
};
</script>

<template>
  <modal :id="id" :options="modalOptions" :title="$t('Order.guestTokenRequestModal.findYourOrder')">
    <template #content>
      <phoenix-form
        ref="phoenixForm"
        v-model="fields"
        :action="action"
        :prefill-enabled="true"
        :captcha-enabled="false"
        :should-display-required-text="false"
        :should-reset-form-on-submit="true"
        :on-success-callback="onSendSuccess"
        :show-errors-in-toast="false"
        data-cy="guest-order-token-request-modal"
      >
        <div class="flex flex-col gap-md">
          <form-group :attrs="fields.orderId">
            <text-field v-model="fields.orderId.value" />
          </form-group>
          <form-group :attrs="fields.email">
            <text-field v-model="fields.email.value" />
          </form-group>
        </div>
      </phoenix-form>
    </template>
  </modal>
</template>
